<template>
  <div>

    <header-profil to="company">
      <div class="px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold">{{ company.name }}</h1>
        <p class="text-lg font-medium">{{ company.type ? company.type.designation : "" }}</p>
      </div>
      <v-divider></v-divider>
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div class="overflow-hidden sm:rounded-lg dark:bg-gray-900">
            <div class="p-4 sm:p-6">
              <div><strong>Immeuble:</strong> Ffffffffffffffff</div>
              <div><strong>BP:</strong> 3000 Douala, Cameroon 🇨🇲</div>
              <div>
                <span class="font-bold">Telephone:</span> {{ company.telephone }}
              </div>
              <div>
                <span class="font-bold">Email:</span>
                <a href="mailto:contact@alain.com" class="text-blue-500 underline">{{ company.email }}</a>
              </div>
            </div>
          </div>
          <!-- <div class="overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
            </div>
          </div> -->
          <div class="overflow-hidden sm:rounded-lg dark:bg-gray-900">
            <div class="px-4 py-5 sm:p-6">
              <div>
                <span class="font-semibold">Local (country of immatriculation):</span> 60%
              </div>
              <div>
                <span class="font-semibold">Other African country:</span> 30%
              </div>
              <div>
                <span class="font-semibold">Non African:</span> 10%
              </div>
            </div>
          </div>
        </div>
      </div>
    </header-profil>
    <ul class="nav-dash-tab my-4">
      <li v-for="(tab, i) in tabs" :key="i" class="font-bold uppercase" v-on:click="toggleTabs(i + 1)"
        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
        {{ tab.title }}
      </li>
    </ul>
    <!-- <v-card class="">
      <header-profil to="professional" :profil="company"></header-profil>
      <v-tabs v-model="active_tab" hide-slider elevation="0">
        <v-tab v-for="(tab, index) of tabs" :key="index" :to="tab.link" exact class="tab-style"
          active-class='primaryAccent'>
          <v-icon size="20" color="primary" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <router-view></router-view>
    </v-card> -->


    <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
      <!-- Products Section -->
      <section class="pa-4 mt-4 border-gray-300 shadow bg-white">
        <h2 class="font-bold text-lg mb-2">Products</h2>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="(product, index) in products" :key="index"
            class="border p-2 rounded text-center bg-gray-50 shadow">
            <img :src="product.image" alt="" class="h-16 w-16 mx-auto mb-2">
            <p>{{ product.name }}</p>
            <button class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
              @click="viewImage(product.image)">View</button>
            <button class="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
              @click="editProduct(index)">Edit</button>
            <button class="bg-red-500 text-white px-2 py-1 mt-2 rounded hover:bg-red-600"
              @click="deleteProduct(index)">X</button>
          </div>
        </div>
        <div class="mt-4">
          <input v-model="newProduct.name" placeholder="Nom du produit" class="border p-2 mr-2 rounded" />
          <input v-model="newProduct.image" placeholder="URL de l'image" class="border p-2 mr-2 rounded" />
          <button class="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600" @click="addProduct">Add
            +</button>
        </div>
      </section>

      <!-- <div class="p-1 flex flex-wrap items-center justify-center">

        <div class="flex-shrink-0 m-6 relative overflow-hidden bg-orange-500 rounded-lg max-w-xs shadow-lg">
          <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none"
            style="transform: scale(1.5); opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
          </svg>
          <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
              style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;">
            </div>
          </div>
          <div class="relative text-white px-6 pb-6 mt-6">
            <span class="block opacity-75 -mb-1">Indoor</span>
            <div class="flex justify-between">
              <span class="block font-semibold text-xl">Peace Lily</span>
              <span
                class="block bg-white rounded-full text-orange-500 text-xs font-bold px-3 py-2 leading-none flex items-center">$36.00</span>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 m-6 relative overflow-hidden bg-teal-500 rounded-lg max-w-xs shadow-lg">
          <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none"
            style="transform: scale(1.5); opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
          </svg>
          <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
              style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;">
            </div>
          </div>
          <div class="relative text-white px-6 pb-6 mt-6">
            <span class="block opacity-75 -mb-1"> </span>
            <div class="flex justify-between">
              <span class="block font-semibold text-xl"> </span>
              <span
                class="block bg-white rounded-full text-teal-500 text-xs font-bold px-3 py-2 leading-none flex items-center">$45.00</span>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 m-6 relative overflow-hidden bg-purple-500 rounded-lg max-w-xs shadow-lg">
          <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none"
            style="transform: scale(1.5); opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
          </svg>
          <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
              style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;">
            </div>
            <img class="relative w-40"
              src="https://user-images.githubusercontent.com/2805249/64069899-8bdaa180-cc97-11e9-9b19-1a9e1a254c18.png"
              alt="">
          </div>
          <div class="relative text-white px-6 pb-6 mt-6">
            <span class="block opacity-75 -mb-1"> </span>
            <div class="flex justify-between">
              <span class="block font-semibold text-xl"> </span>
              <span
                class="block bg-white rounded-full text-purple-500 text-xs font-bold px-3 py-2 leading-none flex items-center">$68.50</span>
            </div>
          </div>
        </div>

        <div class="flex-shrink-0 m-6 relative overflow-hidden bg-purple-500 rounded-lg max-w-xs shadow-lg">
          <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none"
            style="transform: scale(1.5); opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
          </svg>
          <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
              style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;">
            </div>
            <img class="relative w-40"
              src="https://user-images.githubusercontent.com/2805249/64069899-8bdaa180-cc97-11e9-9b19-1a9e1a254c18.png"
              alt="">
          </div>
          <div class="relative text-white px-6 pb-6 mt-6">
            <span class="block opacity-75 -mb-1"> </span>
            <div class="flex justify-between">
              <span class="block font-semibold text-xl"> </span>
              <span
                class="block bg-white rounded-full text-purple-500 text-xs font-bold px-3 py-2 leading-none flex items-center">$68.50</span>
            </div>
          </div>
        </div>

        <div class="flex-shrink-0 m-6 relative overflow-hidden bg-purple-500 rounded-lg max-w-xs shadow-lg">
          <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none"
            style="transform: scale(1.5); opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
          </svg>
          <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
              style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;">
            </div>
            <img class="relative w-40"
              src="https://user-images.githubusercontent.com/2805249/64069899-8bdaa180-cc97-11e9-9b19-1a9e1a254c18.png"
              alt="">
          </div>
          <div class="relative text-white px-6 pb-6 mt-6">
            <span class="block opacity-75 -mb-1">Outdoor</span>
            <div class="flex justify-between">
              <span class="block font-semibold text-xl">Oak Tree</span>
              <span
                class="block bg-white rounded-full text-purple-500 text-xs font-bold px-3 py-2 leading-none flex items-center">$68.50</span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Services Section -->
      
      <section class="p-4 mt-4 border-gray-300 shadow bg-white">
        <h2 class="font-bold text-lg mb-2">Services</h2>
        <div class="grid grid-cols-3 gap-4">
          <div v-for="(service, index) in services" :key="index"
            class="border p-2 rounded text-center bg-gray-50 shadow">
            <img :src="service.image" alt="" class="h-16 w-16 mx-auto mb-2">
            <p>{{ service.name }}</p>
            <button class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
              @click="viewImage(service.image)">View</button>
            <button class="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
              @click="editService(index)">Edit</button>
            <button class="bg-red-500 text-white px-2 py-1 mt-2 rounded hover:bg-red-600"
              @click="deleteService(index)">X</button>
          </div>
        </div>
        <div class="mt-4">
          <input v-model="newService.name" placeholder="Nom du service" class="border p-2 mr-2 rounded" />
          <input v-model="newService.image" placeholder="URL de l'image" class="border p-2 mr-2 rounded" />
          <button class="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600" @click="addService">Add
            +</button>
        </div>
      </section>

      <!-- Vacancies Section -->
      <section class="p-4 mt-4 border-gray-300 shadow bg-white">
        <h2 class="font-bold text-lg mb-2">Vacancies</h2>
        <ul class="space-y-2">
          <li v-for="(vacancy, index) in vacancies" :key="index" class="mb-2">
            <a href="#" class="text-blue-500 underline">{{ vacancy.title }}</a>
            <button class="bg-blue-500 text-white px-2 py-1 ml-2 rounded hover:bg-blue-600"
              @click="viewVacancy(index)">View</button>
            <button class="bg-yellow-500 text-white px-2 py-1 ml-2 rounded hover:bg-yellow-600"
              @click="editVacancy(index)">Edit</button>
            <button class="bg-red-500 text-white px-2 py-1 ml-2 rounded hover:bg-red-600"
              @click="deleteVacancy(index)">X</button>
          </li>
        </ul>
        <button class="bg-blue-500 text-white px-4 py-2 mt-4 rounded shadow hover:bg-blue-600" @click="addVacancy">Add
          +</button>
      </section>

      <!-- Proposals Section -->
      <section class="p-4 mt-4 border-gray-300 shadow bg-white">
        <h2 class="font-bold text-lg mb-2">Request for Proposals</h2>
        <ul class="space-y-2">
          <li v-for="(proposal, index) in proposals" :key="index" class="mb-2">
            <a href="#" class="text-blue-500 underline">{{ proposal.title }}</a>
            <button class="bg-blue-500 text-white px-2 py-1 ml-2 rounded hover:bg-blue-600"
              @click="viewProposal(index)">View</button>
            <button class="bg-yellow-500 text-white px-2 py-1 ml-2 rounded hover:bg-yellow-600"
              @click="editProposal(index)">Edit</button>
            <button class="bg-red-500 text-white px-2 py-1 ml-2 rounded hover:bg-red-600"
              @click="deleteProposal(index)">X</button>
          </li>
        </ul>
        <button class="bg-blue-500 text-white px-4 py-2 mt-4 rounded shadow hover:bg-blue-600" @click="addProposal">Add
          +</button>
      </section>
    </div>

    <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
      <div class="col-span-4 sm:col-span-9">
        <div class="bg-white shadow rounded-lg p-6">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Summary</h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
            <div class="grid grid-cols-1 gap-5 sm:grid-cols-4">
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="p-4 sm:p-6">
                  <dl v-if="loader.rfp.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.rfp.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.rfp.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.rfc.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.rfc.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.rfc.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.vacancies.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.vacancies.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.vacancies.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
              <div class="bg-white overflow-hidden shadow sm:rounded-lg dark:bg-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <dl v-if="loader.service.loading === false">
                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                      {{ datas.service.title }}</dt>
                    <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600 dark:text-indigo-400">
                      {{ datas.service.nb_element }}
                    </dd>
                  </dl>
                  <dl v-else>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg pa-4 my-2">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white">Pricing
          </h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-4 lg:px-8">
            <pricing-resume-card :datas="{ company: datas.company, user: datas.professionals }"></pricing-resume-card>
          </div>
        </div>
        <div class="bg-white shadow rounded-lg pa-4 my-2">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl dark:text-white  mt-15">Activity
          </h2>
          <div class="max-w-7xl mx-auto px-0 sm:px-6 lg:py-5 lg:px-8">
            <card-linear-chart :datasheet="activitySheet"></card-linear-chart>
          </div>
        </div>
      </div>
    </div>

    <div v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
      <v-row class="pa-0">
        <v-col cols="12" md="5" class="pa-0">
          <account-infos></account-infos>
        </v-col>
        <v-col cols="12" md="7" class="pr-2">
          <account-settings></account-settings>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AccountSettings from './account/AccountSettings.vue'
import AccountInfos from './account/AccountInfos.vue'
import HeaderProfil from '@/views/dashboard/components/HeaderProfil.vue'
import CardLinearChart from '@/views/dashboard/components/CardLinearChart.vue'
import PricingResumeCard from '../components/PricingResumeCard.vue';
import { Drequest } from '@/plugins/Drequest'

export default {
  components: {
    AccountSettings,
    AccountInfos,
    HeaderProfil,
    CardLinearChart,
    PricingResumeCard
  },
  data() {
    return {
      active_tab: 'company.profile',

      activitySheet: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Call for proposals',
          data: [5, 0, 10, 5, 2, 3, 14],
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          borderColor: 'rgba(255, 99, 132, 0.6)',
          borderWidth: 1
        },
        {
          label: 'Call for colaboration',
          data: [0, 2, 4, 1, 4, 6, 2],
          backgroundColor: 'rgba(0, 0, 10, 0.1)',
          borderColor: 'rgba(0, 0, 10, 0.4)',
          borderWidth: 1
        },
        {
          label: 'Vacancies',
          data: [2, 6, 4, 5, 14, 6, 2],
          backgroundColor: 'rgba(24, 240, 200, 0.1)',
          borderColor: 'rgba(24, 240, 200, 0.8)',
          borderWidth: 1
        },
        {
          label: 'Service and product',
          data: [14, 10, 15, 5, 8, 12, 6],
          backgroundColor: 'rgba(11, 156, 49, 0.1)',
          borderColor: 'rgba(11, 156, 49, 0.8)',
          borderWidth: 1
        }]
      },

      datas: {
        rfp: {
          title: 'Call for proposals',
          values: {},
          nb_element: 0,
          sent: {
            title: "",
            value: 0
          },
          received: 0,
          confirm: 0
        },
        vacancies: {
          title: "Total vacancies",
          values: {},
          nb_element: 0
        },

        service: {
          title: "Services and products",
          values: {},
          nb_element: 0
        },
        rfc: {
          title: "Call for colaboration",
          values: {},
          nb_element: 0
        },
        company: {
          title: "Companies",
          values: {},
          nb_element: 0
        },
        professionals: {
          title: "Professionals",
          values: {},
          nb_element: 0
        }
      },
      loader: {
        vacancies: {
          loading: true,
          valueReady: false
        },

        service: {
          loading: true,
          valueReady: false
        },
        rfp: {
          loading: true,
          valueReady: false
        },
        rfc: {
          loading: true,
          valueReady: false
        },
        company: {
          loading: true,
          valueReady: false
        },
        professionals: {
          loading: true,
          valueReady: false
        }
      },
      company: {},
      tabs: [
        { title: 'Informations', icon: mdiAccountOutline, link: { name: 'company.account.profile' } },
        { title: 'Edit profil', icon: mdiAccountOutline, link: { name: 'company.account.setting' } },
      ],
      openTab: 1,
      tabs: [
        { title: 'Home', icon: mdiAccountOutline },
        { title: 'Activity', icon: mdiAccountOutline },
        { title: 'Profile', icon: mdiAccountOutline }
      ],
      // @override

      products: [
        { name: 'Bananas', image: 'https://via.placeholder.com/150' },
        { name: 'Avocados', image: 'https://via.placeholder.com/150' },
        { name: 'Papaya', image: 'https://via.placeholder.com/150' },
      ],
      newProduct: { name: '', image: '' },
      services: [
        { name: 'Development web', image: 'https://via.placeholder.com/150' },
        { name: 'Trading et change', image: 'https://via.placeholder.com/150' },
        { name: 'Controle fiduciere', image: 'https://via.placeholder.com/150' },
      ],
      newService: { name: '', image: '' },
      vacancies: [
        { title: 'Agent de maitrise Bacc+2' },
      ],
      newVacancy: { title: '' },
      proposals: [
        { title: 'Forage a Bangui' },
      ],
      newProposal: { title: '' },
    }
  },

  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    },

    // toggleTabs(status) {
    //   this.editAccount = status
    // },
    fetchCompanyInformation() {
      Drequest.api(`enterprise.fetch?keyid=${this.$store.getters.getCompany.id}`).get((response) => {
        if (response.success === true) {
          this.company = response.enterprise
          console.log(this.company)
        }
        else { this.loading = false }
      }).catch((err) => { this.loading = false });
    },

    fetchData() {
      this.loader.company = true
      Drequest.api(`lazyloading.enterprise?dfilters=on&enterprise.id:eq=${this.$route.params.ids}&next=1&per_page=1`)
        .get((response) => {
          if (response.success === true) {
            this.company = response.listEntity[0]
            console.log(this.company)
            this.loader.company = false
          }
          else {
            this.loader.company = false
          }
        })
        .catch((err) => {
          this.loader.company = false
        });
    },
    // Products Methods
    addProduct() {
      if (this.newProduct.name && this.newProduct.image) {
        this.products.push({ ...this.newProduct });
        this.newProduct = { name: '', image: '' };
      }
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
    editProduct(index) {
      const updatedName = prompt('Modifier le nom du produit:', this.products[index].name);
      const updatedImage = prompt('Modifier l\'URL de l\'image:', this.products[index].image);
      if (updatedName) this.products[index].name = updatedName;
      if (updatedImage) this.products[index].image = updatedImage;
    },
    viewImage(imageUrl) {
      window.open(imageUrl, '_blank');
    },
    // Services Methods
    addService() {
      if (this.newService.name && this.newService.image) {
        this.services.push({ ...this.newService });
        this.newService = { name: '', image: '' };
      }
    },
    deleteService(index) {
      this.services.splice(index, 1);
    },
    editService(index) {
      const updatedName = prompt('Modifier le nom du service:', this.services[index].name);
      const updatedImage = prompt('Modifier l\'URL de l\'image:', this.services[index].image);
      if (updatedName) this.services[index].name = updatedName;
      if (updatedImage) this.services[index].image = updatedImage;
    },
    // Vacancies Methods
    addVacancy() {
      const newTitle = prompt('Ajouter un titre pour la nouvelle offre:');
      if (newTitle) this.vacancies.push({ title: newTitle });
    },
    deleteVacancy(index) {
      this.vacancies.splice(index, 1);
    },
    editVacancy(index) {
      const updatedTitle = prompt('Modifier le titre de l\'offre:', this.vacancies[index].title);
      if (updatedTitle) this.vacancies[index].title = updatedTitle;
    },
    viewVacancy(index) {
      alert('Visualiser les détails de l\'offre : ' + this.vacancies[index].title);
      // Code pour ouvrir une nouvelle page avec les détails
    },
    // Proposals Methods
    addProposal() {
      const newTitle = prompt('Ajouter un titre pour la nouvelle proposition:');
      if (newTitle) this.proposals.push({ title: newTitle });
    },
    deleteProposal(index) {
      this.proposals.splice(index, 1);
    },
    editProposal(index) {
      const updatedTitle = prompt('Modifier le titre de la proposition:', this.proposals[index].title);
      if (updatedTitle) this.proposals[index].title = updatedTitle;
    },
    viewProposal(index) {
      alert('Visualiser les détails de la proposition : ' + this.proposals[index].title);
      // Code pour ouvrir une nouvelle page avec les détails
    },
  },
  beforeMount() {
    this.fetchCompanyInformation(),
      Drequest.api(`lazyloading.user`).get((response) => {
        if (response.success === true) {
          this.datas.professionals.nb_element = response.nb_element
          this.loader.professionals.loading = false
          this.loader.professionals.valueReady = true
          // console.log(response)
        }
        else {
          this.loader.professionals.loading = false
          this.loader.professionals.valueReady = false
        }
      }).catch((err) => {
        this.loader.professionals.loading = false
        this.loader.professionals.valueReady = false

      });
    Drequest.api(`lazyloading.enterprise`).get((response) => {
      if (response.success === true) {
        this.datas.company.nb_element = response.nb_element
        this.loader.company.loading = false
        this.loader.company.valueReady = true
        // console.log(response)
      }
      else {
        this.loader.company.loading = false
        this.loader.company.valueReady = false
      }
    }).catch((err) => {
      this.loader.company.loading = false
      this.loader.company.valueReady = false

    });


    // Drequest.api(`lazyloading.colaborator?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
    //   if (response.success === true) {
    //     this.datas.rfc.nb_element = response.nb_element
    //     // this.dashservices[0].items.push({ title: 'Sent', value: response.nb_element })

    //     this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = true
    //     console.log( response)
    //   }
    //   else {
    //     this.dashservices[0].items.push({ title: 'Sent', value: 0 }),
    //       this.loader.rfc.loading = false
    //     this.loader.rfc.valueReady = false
    //   }
    // }).catch((err) => {
    //   this.loader.rfc.loading = false
    //   this.loader.rfc.valueReady = false
    // });

    // Drequest.api(`lazyloading.colaborator?dfilters=on&recipientuser:eq=${this.$store.getters.getUser.id}`).get((response) => {
    Drequest.api(`lazyloading.rfp?dfilters=on&enterprise_id=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.rfp.nb_element = response.nb_element
        this.loader.rfp.loading = false
        this.loader.rfp.valueReady = true
        // console.log(response)
      }
      else {
        this.loader.rfp.loading = false
        this.loader.rfp.valueReady = false
      }
    }).catch((err) => {
      this.loader.rfp.loading = false
      this.loader.rfp.valueReady = false
    });

    //   Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`).get((response) => {
    //     if (response.success === true) {
    //       this.networkdata.confirm = response.nb_element
    //       // this.dashservices[0].items.push({ title: 'Colaborators', value: response.nb_element })
    //     }
    //     else { this.dashservices[0].items.push({ title: 'Colaborators', value: 0 }) }
    //   }).catch((err) => { });

    Drequest.api(`lazyloading.rfc?dfilters=on&enterprise_id=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.rfc.nb_element = response.nb_element
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = true
        // console.log(response)
      }
      else {
        this.loader.rfc.loading = false
        this.loader.rfc.valueReady = false
      }
    }).catch((err) => {
      this.loader.rfc.loading = false
      this.loader.rfc.valueReady = false
    });

    Drequest.api(`lazyloading.job?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.vacancies.nb_element = response.nb_element
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = true
        // console.log(response)
      }
      else {
        this.loader.vacancies.loading = false
        this.loader.vacancies.valueReady = false
      }
    }).catch((err) => {
      this.loader.vacancies.loading = false
      this.loader.vacancies.valueReady = false
    });

    Drequest.api(`lazyloading.service?dfilters=on&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
      if (response.success === true) {
        this.datas.service.nb_element = response.nb_element
        this.loader.service.loading = false
        this.loader.service.valueReady = true
        // console.log(response)
      }
      else {
        this.loader.service.loading = false
        this.loader.service.valueReady = false
      }
    }).catch((err) => {
      this.loader.service.loading = false
      this.loader.service.valueReady = false
    });

  },

  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';
</style>
